import React from "react";
import { graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";

import { Base, LimitMaxWidth, SubscribeBox } from "../components/layout";
import PostsContent from "../components/page-contents/PostsContent";
import SEO from "../components/seo";

// import bottomBannerPng from "../assets/images/blog-banner.png";
// import bottomBannerAvif from "../assets/images/blog-banner.avif";

type Props = {
  data: any
}

export const Head = () => <SEO title="Syrona Health | Blog" />

const BlogPage: React.FC<Props> = ({ data }) => <Base>
  <PostsContent
    title="Explore Our Blog"
    nodes={data.allPrismicBlogPost.nodes}
    filterOnCategory={true}
    // bottomBanner={SubscribeSection}
    linkCreator={uid => `/blog/${uid}`}
  />
</Base>

export default BlogPage;

export const query = graphql`
  query AllPrismicBlogPost(
    $order: [SortOrderEnum]
    $fields: [PrismicBlogPostFieldsEnum]
  ) {
    allPrismicBlogPost(sort: { order: $order, fields: $fields }) {
      nodes {
        data {
          categories
          author
          title {
            text
          }
          content {
            text
          }
          cover {
            url
          }
        }
        uid
        first_publication_date
      }
    }
  }
`

// const SubscribeSection: React.FC = () => {
//   return <>
//     <div className="mb-12 mx-8">
//       <LimitMaxWidth>
//         <div className="flex max-md:flex-col justify-center items-center">
//           <div className="max-md:order-1 max-md:h-128 md:w-4/12">
//             <picture className="h-full object-scale-down">
//               <source srcSet={bottomBannerAvif} type="image/avif" />
//               <img src={bottomBannerPng} alt=""/>
//             </picture>
//           </div>
//           <div className="max-md:order-0 md:w-5/12 flex flex-col justify-center items-start space-y-4">
//             <h4 className="text-h4 font-dm text-black">Want exclusive content?</h4>
//             <p className="text-b1 font-nun text-black">
//               Subscribe to our newsletter! Get the latest information and updates from our blog each month.
//             </p>
//             <SubscribeBox />
//             <p className="text-b1 font-nun text-black">
//               By clicking "Subscribe" you consent to receiving content from Syrona Health. 
//             </p>
//           </div>
//         </div>
//       </LimitMaxWidth>
//     </div>
//   </>
// }
